<template>
    <v-menu bottom left>
    <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" >
            <v-app-bar-nav-icon class="grey--text"></v-app-bar-nav-icon>
        </v-btn>
    </template>
    <v-list>
        <router-link class="del-underline" to="/">
            <v-list-item>Stats Summary</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/heikin-ashi/stats">
            <v-list-item>HA Stats</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/market/OkexMarkets">
            <v-list-item >OKEx</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/market/BinanceMarkets">
            <v-list-item >Binance</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/market/KrakenMarkets">
            <v-list-item >Kraken</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/CryptoPriceMarketCap">
            <v-list-item>CMC</v-list-item>
        </router-link>
        <!--信号-->
        <router-link class="del-underline" to="/signals">
            <v-list-item>Signal</v-list-item>
        </router-link>
        <!--利润-->
        <router-link class="del-underline" to="/portfolio">
            <v-list-item>Portfolio</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/research">
            <v-list-item>Research</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/user/NAV">
            <v-list-item>DeFi Funds</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/address">
            <v-list-item>Address</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/pool">
            <v-list-item>Pools Fee</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/pool/fee/stats">
            <v-list-item>Pools Fee Stats</v-list-item>
        </router-link>
        <!--通知-->
        <router-link class="del-underline" to="/notifications">
            <v-list-item>Notifications</v-list-item>
        </router-link>
        <!-- 摘要首页-->
        <router-link class="del-underline" to="/overview">
            <v-list-item>Dashboard</v-list-item>
        </router-link>
        <!--用户-->
        <router-link class="del-underline" to="/user">
            <v-list-item>Users</v-list-item>
        </router-link>
        <!--访问量-->
        <router-link class="del-underline" to="/visit">
            <v-list-item>Visit</v-list-item>
        </router-link>
        <router-link class="del-underline" to="/version">
            <v-list-item>Version</v-list-item>
        </router-link>
        <!-- redis -->
        <router-link class="del-underline" to="/system/redis">
            <v-list-item>Redis</v-list-item>
        </router-link>
    </v-list>
    </v-menu>
</template>

<script>
import icon from "../components/icon"
export default {
    name: "",
    data: () => ({

    }),
    computed: {
    },
    components:{
        icon
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {

    }
  }
</script>