import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/store'
import vuetify from './plugins/vuetify';
import ajax from './plugins/ajax';
import Vuex from "vuex";
import "../static/css/global.css";
import echarts from 'echarts';
// 引用世界地图
import 'echarts/map/js/world.js'
// 引用中国地图
import 'echarts/map/js/china.js'
import common from '../static/js/common';
import $ from 'jquery';

// JS
// 初始化数据
import init from './store/init';

// Highcharts
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

// **********完整饿了么ui**********
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// **********部分饿了么ui**********
// import { Table,TableColumn} from 'element-ui'  // 引入表格部分ui
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.component(Table.name, Table)
// Vue.component(TableColumn.name, TableColumn)

// json View 文档
// https://github.com/chenfengjw163/vue-json-viewer/blob/master/README_CN.md


Vue.config.productionTip = false
Vue.use(Vuex);
// Vue.use(echarts);
Vue.prototype.$echarts = echarts
Vue.prototype.common = common;
Vue.prototype.$ = $;
Vue.use(init);

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts);
HighchartsNoData(Highcharts);

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) },
  created: function() {
    let token = this.$store.getters.user.token;
    Vue.http.interceptors.push((request, next) => {
      // 接口每次请求会跨域携带cookie
      request.credentials = false;
      if(Number(localStorage.getItem("iobots-admin-expires"))-((new Date()).getTime()) < 0 && token !=null){
        localStorage.removeItem("iobots-admin-token");
        localStorage.removeItem("iobots-admin-expires")
        localStorage.removeItem("iobots-admin-user")
        this.$router.go();
        // this.$router.push({name: 'login'})
      }else if(Number(localStorage.getItem("iobots-admin-expires"))-((new Date()).getTime()) > 0 && token !=null){
        // localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 30 )
        localStorage.setItem("iobots-admin-expires", (new Date()).getTime() + 1000 * 60 * 60 * 24 * 7)
      }
      if(token){
        // 请求headers携带参数
        request.headers.set('Authorization', 'Bearer '+ token);
      }
      next((response) => {
        if(response.data.code == 500){
           this.$store.dispatch('snackbarMessageHandler', response.data.message);
        }
        return response;
      });
    });
    this.init();
  },
  
}).$mount('#app')
