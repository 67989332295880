<template>
    <v-row>
        <v-col cols="12">
            <h5 class="text-center mb-5">Last 30 Days</h5>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th class="text-right">current</th>
                            <th class="text-right">ROE</th>
                            <th class="text-right">P/L</th>
                            <th class="text-right">Starting Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in portfolioLast.day30" :key="index">
                            <td class="font-12">{{item.symbol}}</td>
                            <td class="font-12 text-right">${{formatNum(item.current.toFixed(2))}}</td>
                            <td class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</td>
                            <td class="font-12 text-right">${{formatNum(item.starting.toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td class="font-12">Total</td>
                            <td class="font-12 text-right">${{formatNum(current30)}}</td>
                            <td class="font-12 text-right">{{((current30 - starting30) / starting30 * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right">${{formatNum(pl30)}}</td>
                            <td class="font-12 text-right">${{formatNum(starting30)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
            <h5 class="text-center mb-5">Last 60 Days</h5>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th class="text-right">current</th>
                            <th class="text-right">ROE</th>
                            <th class="text-right">P/L</th>
                            <th class="text-right">Starting Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in portfolioLast.day60" :key="index">
                            <td class="font-12">{{item.symbol}}</td>
                            <td class="font-12 text-right">${{formatNum(item.current.toFixed(2))}}</td>
                            <td class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</td>
                            <td class="font-12 text-right">${{formatNum(item.starting.toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td class="font-12">Total</td>
                            <td class="font-12 text-right">${{formatNum(current60)}}</td>
                            <td class="font-12 text-right">{{((current60 - starting60) / starting60 * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right">${{formatNum(pl60)}}</td>
                            <td class="font-12 text-right">${{formatNum(starting60)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <h5 class="text-center mb-5">Last 1 Quarter</h5>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th class="text-right">current</th>
                            <th class="text-right">ROE</th>
                            <th class="text-right">P/L</th>
                            <th class="text-right">Starting Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in portfolioLast.day90" :key="index">
                            <td class="font-12">{{item.symbol}}</td>
                            <td class="font-12 text-right">${{formatNum(item.current.toFixed(2))}}</td>
                            <td class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</td>
                            <td class="font-12 text-right">${{formatNum(item.starting.toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td class="font-12">Total</td>
                            <td class="font-12 text-right">${{formatNum(current90)}}</td>
                            <td class="font-12 text-right">{{((current90 - starting90) / starting90 * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right">${{formatNum(pl90)}}</td>
                            <td class="font-12 text-right">${{formatNum(starting90)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="mt-5">
            <h5 class="text-center mb-5">Last 6 Months</h5>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th class="text-right">current</th>
                            <th class="text-right">ROE</th>
                            <th class="text-right">P/L</th>
                            <th class="text-right">Starting Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in portfolioLast.day180" :key="index">
                            <td class="font-12">{{item.symbol}}</td>
                            <td class="font-12 text-right">${{formatNum(item.current.toFixed(2))}}</td>
                            <td class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</td>
                            <td class="font-12 text-right">${{formatNum(item.starting.toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td class="font-12">Total</td>
                            <td class="font-12 text-right">${{formatNum(current180)}}</td>
                            <td class="font-12 text-right">{{((current180 - starting180) / starting180 * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right">${{formatNum(pl180)}}</td>
                            <td class="font-12 text-right">${{formatNum(starting180)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="mt-5">
            <h5 class="text-center mb-5">Since Inceptions</h5>
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th class="text-right">current</th>
                            <th class="text-right">ROE</th>
                            <th class="text-right">P/L</th>
                            <th class="text-right">Starting Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in portfolioLast.day360" :key="index">
                            <td class="font-12">{{item.symbol}}</td>
                            <td class="font-12 text-right">${{formatNum(item.current.toFixed(2))}}</td>
                            <td class="font-12 text-right" :class="item.roe < 0 ? 'red--text' : ''">{{(item.roe * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right" :class="item.pl < 0 ? 'red--text' : ''">${{formatNum(item.pl.toFixed(2))}}</td>
                            <td class="font-12 text-right">${{formatNum(item.starting.toFixed(2))}}</td>
                        </tr>
                        <tr>
                            <td class="font-12">Total</td>
                            <td class="font-12 text-right">${{formatNum(current360)}}</td>
                            <td class="font-12 text-right ">{{((current360 - starting360) / starting360 * 100).toFixed(2)}}%</td>
                            <td class="font-12 text-right">${{formatNum(pl360)}}</td>
                            <td class="font-12 text-right">${{formatNum(starting360)}}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-divider></v-divider>
        </v-col>
    </v-row>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import {mapGetters} from "vuex";
    // 引入导出Excel表格依赖
    import FileSaver from "file-saver";
    import XLSX from "xlsx";

    export default {
        data() {
            return {
                portfolioLast:{},
            }
        },
        computed: {
            ...mapGetters(['user','lastObj']),
            current30(){
                var total = 0;
                if(this.portfolioLast.day30 != null && this.portfolioLast.day30.length > 0){
                    for(var i = 0; i < this.portfolioLast.day30.length ; i++){
                        total += this.portfolioLast.day30[i].current;
                    }
                }
                return total.toFixed(2);
            },
            pl30(){
                var total = 0;
                if(this.portfolioLast.day30 != null && this.portfolioLast.day30.length > 0){
                    for(var i = 0; i < this.portfolioLast.day30.length ; i++){
                        total += this.portfolioLast.day30[i].pl;
                    }
                }
                return total.toFixed(2) ;
            },
            starting30(){
                var total = 0;
                if(this.portfolioLast.day30 != null && this.portfolioLast.day30.length > 0){
                    for(var i = 0; i < this.portfolioLast.day30.length ; i++){
                        total += this.portfolioLast.day30[i].starting;
                    }
                }
                return total.toFixed(2) ;
            },
            current60(){
                var total = 0;
                if(this.portfolioLast.day60 != null && this.portfolioLast.day60.length > 0){
                    for(var i = 0; i < this.portfolioLast.day60.length ; i++){
                        total += this.portfolioLast.day60[i].current;
                    }
                }
                return total.toFixed(2);
            },
            pl60(){
                var total = 0;
                if(this.portfolioLast.day60 != null && this.portfolioLast.day60.length > 0){
                    for(var i = 0; i < this.portfolioLast.day60.length ; i++){
                        total += this.portfolioLast.day60[i].pl;
                    }
                }
                return total.toFixed(2) ;
            },
            starting60(){
                var total = 0;
                if(this.portfolioLast.day60 != null && this.portfolioLast.day60.length > 0){
                    for(var i = 0; i < this.portfolioLast.day60.length ; i++){
                        total += this.portfolioLast.day60[i].starting;
                    }
                }
                return total.toFixed(2) ;
            },
            current90(){
                var total = 0;
                if(this.portfolioLast.day90 != null && this.portfolioLast.day90.length > 0){
                    for(var i = 0; i < this.portfolioLast.day90.length ; i++){
                        total += this.portfolioLast.day90[i].current;
                    }
                }
                return total.toFixed(2);
            },
            pl90(){
                var total = 0;
                if(this.portfolioLast.day90 != null && this.portfolioLast.day90.length > 0){
                    for(var i = 0; i < this.portfolioLast.day90.length ; i++){
                        total += this.portfolioLast.day90[i].pl;
                    }
                }
                return total.toFixed(2) ;
            },
            starting90(){
                var total = 0;
                if(this.portfolioLast.day90 != null && this.portfolioLast.day90.length > 0){
                    for(var i = 0; i < this.portfolioLast.day90.length ; i++){
                        total += this.portfolioLast.day90[i].starting;
                    }
                }
                return total.toFixed(2) ;
            },
            current180(){
                var total = 0;
                if(this.portfolioLast.day180 != null && this.portfolioLast.day180.length > 0){
                    for(var i = 0; i < this.portfolioLast.day180.length ; i++){
                        total += this.portfolioLast.day180[i].current;
                    }
                }
                return total.toFixed(2);
            },
            pl180(){
                var total = 0;
                if(this.portfolioLast.day180 != null && this.portfolioLast.day180.length > 0){
                    for(var i = 0; i < this.portfolioLast.day180.length ; i++){
                        total += this.portfolioLast.day180[i].pl;
                    }
                }
                return total.toFixed(2) ;
            },
            starting180(){
                var total = 0;
                if(this.portfolioLast.day180 != null && this.portfolioLast.day180.length > 0){
                    for(var i = 0; i < this.portfolioLast.day180.length ; i++){
                        total += this.portfolioLast.day180[i].starting;
                    }
                }
                return total.toFixed(2) ;
            },
            current360(){
                var total = 0;
                if(this.portfolioLast.day360 != null && this.portfolioLast.day360.length > 0){
                    for(var i = 0; i < this.portfolioLast.day360.length ; i++){
                        total += this.portfolioLast.day360[i].current;
                    }
                }
                return total.toFixed(2);
            },
            pl360(){
                var total = 0;
                if(this.portfolioLast.day360 != null && this.portfolioLast.day360.length > 0){
                    for(var i = 0; i < this.portfolioLast.day360.length ; i++){
                        total += this.portfolioLast.day360[i].pl;
                    }
                }
                return total.toFixed(2) ;
            },
            starting360(){
                var total = 0;
                if(this.portfolioLast.day360 != null && this.portfolioLast.day360.length > 0){
                    for(var i = 0; i < this.portfolioLast.day360.length ; i++){
                        total += this.portfolioLast.day360[i].starting;
                    }
                }
                return total.toFixed(2) ;
            },
        },
        watch: {},
        mounted() {
            if (!this.user.token) {
                this.$router.push({
                    name: 'login'
                })
            };
            this.getResult();
        },
        created() {

        },
        methods: {
            getResult(){
                let api = config.baseUrl + '/admin/private/getPortfolioLast';
                this.$http.get(api).then(res=>{
                    let data = res.data;
                    if(data.code == 200){
                        this.portfolioLast = data.result;
                    }
                })
            },
            //定义导出Excel表格事件
            exportExcel() {
                /* 从表生成工作簿对象 */
                var today = new Date()
                var wb = XLSX.utils.table_to_book(document.querySelector("#PortfolioLast"));
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], {type: "application/octet-stream"}),
                        //设置导出文件名称
                        "PortfolioLast" + this.common.dateFormat(today) + ".xlsx"
                    );
                } catch (e) {
                    if (typeof console !== "undefined") console.log(e, wbout);
                }
                return wbout;
            },
            // 货币格式化
            formatNum(num) {
                return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        }
    }
</script>