const state = {
	user: {},
	role: null,
	locale: 'en-US'
}

const getters = {
	user: state => {
		// if (state.user.token == null) {
			let user = JSON.parse(localStorage.getItem('iobots-admin-user'));
			if (user) {
				state.user = user;
			// }
		}
		return state.user;
	},
	role: state => {
		let role = JSON.parse(localStorage.getItem('iobots-admin-role'));
		if (role) {
			state.role = role;
		}
		return state.role;
	},
	locale: state => {
		let locale = localStorage.getItem("iobots-admin-locale");
		if (locale) {
			state.locale = locale;
		}
		return state.locale;
	}
}

const actions = {
	userHandler(context, payload){
		context.commit('userHandler', payload);
	},
	roleHandler(context, payload){
		context.commit('roleHandler', payload);
	},
	localeHandler(context, payload){
		context.commit('localeHandler', payload);
	},
	timeZoneHandler(context, payload){
		context.commit('timeZoneHandler', payload);
	}
}

const mutations = {
	userHandler(state, user){
		localStorage.setItem('iobots-admin-user', JSON.stringify(user));
		state.user = user;
	},
	roleHandler(state, role){
		localStorage.setItem('iobots-admin-role', JSON.stringify(role));
		state.role = role;
	},
	localeHandler(state, locale){
		localStorage.setItem("iobots-admin-locale", locale);
		state.locale = locale;
	},
	timeZoneHandler(state, timeZone){
		state.user.timeZoneId = timeZone.timeZoneId;
		state.user.timeOffset = timeZone.timeOffset;
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}