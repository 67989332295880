const state = {
	// 所有信号交易对数组
	signalSymbols: null,
	// 周期的信号交易对数组
	intervalSymbols: null,
	// 信号周期数组
	signalIntervals: null,
	lastObj:{
		total:0,
		last30:0,
		last60:0,
		last90:0,
		last180:0,
		last360:0,
	},
	// 现货交易对详情信息
	spotSymbolInfos: null,
	// 永续USDT合约交易对详情信息
	swapUsdtSymbolInfos: null,
	// 期货交易对的小数位
	futuresDecimalNumbers: null,
	// 交易所支持的现货交易对
	exchangeSupportedSpotSymbols: null,
	// 交易所支持的期货交易对
	exchangeSupportedFuturesSymbols: null,
	// 交易所支持的永续USDT合约交易对
	exchangeSupportedSwapUsdtSymbols: null,
	// 所有交易所
	exchanges: null,
	// 交易类别
	tradingCategories: null,
}

const getters = {
	signalSymbols: state => {
		if (state.signalSymbols) {
			return state.signalSymbols;
		}
		let signalSymbols = JSON.parse(localStorage.getItem('iobots-admin-signalsSymbols'));
		if (signalSymbols) {
			state.signalSymbols = signalSymbols;
		}
		return state.signalSymbols;
	},
	intervalSymbols: state => {
		if (state.intervalSymbols) {
			return state.intervalSymbols;
		}
		let intervalSymbols = JSON.parse(localStorage.getItem('iobots-admin-intervalSymbols'));
		if (intervalSymbols) {
			state.intervalSymbols = intervalSymbols;
		}
		return state.intervalSymbols;
	},
	lastObj: state =>{
		if(state.lastObj){
			return state.lastObj;
		}
		let lastObj = JSON.parse(localStorage.getItem('iobots-admin-lastObj'));
		if (lastObj) {
			state.lastObj = lastObj;
		}
		return state.lastObj;
	},
	signalIntervals: state => {
		let signalIntervals = JSON.parse(localStorage.getItem('iobots-admin-signalIntervals'));
		if (signalIntervals) {
			state.signalIntervals = signalIntervals;
		}
		return state.signalIntervals;
	},
	spotSymbolInfos: state => {
		if (state.spotSymbolInfos) {
			return state.spotSymbolInfos;
		}
		let spotSymbolInfos = JSON.parse(localStorage.getItem('iobots-admin-spotSymbolInfos'));
		if (spotSymbolInfos) {
			state.spotSymbolInfos = spotSymbolInfos;
		}
		return state.spotSymbolInfos;
	},
	swapUsdtSymbolInfos: state => {
		if (state.swapUsdtSymbolInfos) {
			return state.swapUsdtSymbolInfos;
		}
		let swapUsdtSymbolInfos = JSON.parse(localStorage.getItem('iobots-admin-swapUsdtSymbolInfos'));
		if (swapUsdtSymbolInfos) {
			state.swapUsdtSymbolInfos = swapUsdtSymbolInfos;
		}
		return state.swapUsdtSymbolInfos;
	},
	futuresDecimalNumbers: state => {
		if (state.futuresDecimalNumbers) {
			return state.futuresDecimalNumbers;
		}
		let futuresDecimalNumbers = JSON.parse(localStorage.getItem('iobots-admin-futuresDecimalNumbers'));
		if (futuresDecimalNumbers) {
			state.futuresDecimalNumbers = futuresDecimalNumbers;
		}
		return state.futuresDecimalNumbers;
	},
	exchangeSupportedSpotSymbols: state => {
		if (state.exchangeSupportedSpotSymbols) {
			return state.exchangeSupportedSpotSymbols;
		}
		let exchangeSupportedSpotSymbols = JSON.parse(localStorage.getItem('iobots-admin-exchangeSupportedSpotSymbols'));
		if (exchangeSupportedSpotSymbols) {
			state.exchangeSupportedSpotSymbols = exchangeSupportedSpotSymbols;
		}
		return state.exchangeSupportedSpotSymbols;
	},
	exchangeSupportedFuturesSymbols: state => {
		if (state.exchangeSupportedFuturesSymbols) {
			return state.exchangeSupportedFuturesSymbols;
		}
		let exchangeSupportedFuturesSymbols = JSON.parse(localStorage.getItem('iobots-admin-exchangeSupportedFuturesSymbols'));
		if (exchangeSupportedFuturesSymbols) {
			state.exchangeSupportedFuturesSymbols = exchangeSupportedFuturesSymbols;
		}
		return state.exchangeSupportedFuturesSymbols;
	},
	exchangeSupportedSwapUsdtSymbols: state => {
		if (state.exchangeSupportedSwapUsdtSymbols) {
			return state.exchangeSupportedSwapUsdtSymbols;
		}
		let exchangeSupportedSwapUsdtSymbols = JSON.parse(localStorage.getItem('iobots-admin-exchangeSupportedSwapUsdtSymbols'));
		if (exchangeSupportedSwapUsdtSymbols) {
			state.exchangeSupportedSwapUsdtSymbols = exchangeSupportedSwapUsdtSymbols;
		}
			return state.exchangeSupportedSwapUsdtSymbols;
	},
	exchanges: state => {
		if (state.exchanges) {
			return state.exchanges;
		}
		let exchanges = JSON.parse(localStorage.getItem('iobots-admin-exchanges'));
		if (exchanges) {
			state.exchanges = exchanges;
		}
		return state.exchanges;
	},
	tradingCategories: state => {
		if (state.tradingCategories) {
			return state.tradingCategories;
		}
		let tradingCategories = JSON.parse(localStorage.getItem('iobots-admin-tradingCategories'));
		if (tradingCategories) {
			state.tradingCategories = tradingCategories;
		}
		return state.tradingCategories;
	},
}

const actions = {
	signalSymbolsHandler(context, payload){
		context.commit('signalSymbolsHandler', payload);
	},
	intervalSymbolsHandler(context, payload){
		context.commit('intervalSymbolsHandler', payload);
	},
	lastObjSymbolsHandler(context, payload){
		context.commit('lastObjSymbolsHandler', payload);
	},
	signalIntervalsHandler(context, payload){
		context.commit('signalIntervalsHandler', payload);
	},
	spotSymbolInfosHandler(context, payload){
		context.commit('spotSymbolInfosHandler', payload);
	},
	swapUsdtSymbolInfosHandler(context, payload){
		context.commit('swapUsdtSymbolInfosHandler', payload);
	},
	futuresDecimalNumbersHandler(context, payload){
		context.commit('futuresDecimalNumbersHandler', payload);
	},
	exchangeSupportedSpotSymbolsHandler(context, payload){
		context.commit('exchangeSupportedSpotSymbolsHandler', payload);
	},
	exchangeSupportedFuturesSymbolsHandler(context, payload){
		context.commit('exchangeSupportedFuturesSymbolsHandler', payload);
	},
	exchangeSupportedSwapUsdtSymbolsHandler(context, payload){
		context.commit('exchangeSupportedSwapUsdtSymbolsHandler', payload);
	},
	exchangesHandler(context, payload){
		context.commit('exchangesHandler', payload);
	},
	tradingCategoriesHandler(context, payload){
		context.commit('tradingCategoriesHandler', payload);
	},
}

const mutations = {
	signalSymbolsHandler(state, signalSymbols){
		localStorage.setItem('iobots-admin-signalsSymbols', JSON.stringify(signalSymbols));
		state.signalSymbols = signalSymbols;
	},
	intervalSymbolsHandler(state, intervalSymbols){
		localStorage.setItem('iobots-admin-intervalSymbols', JSON.stringify(intervalSymbols));
		state.intervalSymbols = intervalSymbols;
	},
	lastObjSymbolsHandler(state, lastObj){
		localStorage.setItem('iobots-admin-lastObj', JSON.stringify(lastObj));
		state.lastObj = lastObj;
	},
	signalIntervalsHandler(state, signalIntervals){
		localStorage.setItem('iobots-signalIntervals', JSON.stringify(signalIntervals));
		state.signalIntervals = signalIntervals;
	},
	spotSymbolInfosHandler(state, spotSymbolInfos){
		localStorage.setItem('iobots-admin-spotSymbolInfos', JSON.stringify(spotSymbolInfos));
		state.spotSymbolInfos = spotSymbolInfos;
	},
	swapUsdtSymbolInfosHandler(state, swapUsdtSymbolInfos){
		localStorage.setItem('iobots-admin-swapUsdtSymbolInfos', JSON.stringify(swapUsdtSymbolInfos));
		state.swapUsdtSymbolInfos = swapUsdtSymbolInfos;
	},
	futuresDecimalNumbersHandler(state, futuresDecimalNumbers){
		localStorage.setItem('iobots-admin-futuresDecimalNumbers', JSON.stringify(futuresDecimalNumbers));
		state.futuresDecimalNumbers = futuresDecimalNumbers;
	},
	exchangeSupportedSpotSymbolsHandler(state, exchangeSupportedSpotSymbols){
		localStorage.setItem('iobots-admin-exchangeSupportedSpotSymbols', JSON.stringify(exchangeSupportedSpotSymbols));
		state.exchangeSupportedSpotSymbols = exchangeSupportedSpotSymbols;
	},
	exchangeSupportedFuturesSymbolsHandler(state, exchangeSupportedFuturesSymbols){
		localStorage.setItem('iobots-admin-exchangeSupportedFuturesSymbols', JSON.stringify(exchangeSupportedFuturesSymbols));
		state.exchangeSupportedFuturesSymbols = exchangeSupportedFuturesSymbols;
	},
	exchangeSupportedSwapUsdtSymbolsHandler(state, exchangeSupportedSwapUsdtSymbols){
		localStorage.setItem('iobots-admin-exchangeSupportedSwapUsdtSymbols', JSON.stringify(exchangeSupportedSwapUsdtSymbols));
		state.exchangeSupportedSwapUsdtSymbols = exchangeSupportedSwapUsdtSymbols;
	},
	exchangesHandler(state, exchanges){
		localStorage.setItem('iobots-admin-exchanges', JSON.stringify(exchanges));
		state.exchanges = exchanges;
	},
	tradingCategoriesHandler(state, tradingCategories){
		localStorage.setItem('iobots-admin-tradingCategories', JSON.stringify(tradingCategories));
		state.tradingCategories = tradingCategories;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}