const state = {
	isShowSnackbar: false,          	// 是否显示提示信息
    snackbarMessage: null 				// 提示信息
}

const getters = {
	isShowSnackbar: state => {
		return state.isShowSnackbar;
	},
	snackbarMessage: state => {
		return state.snackbarMessage;
	}
}

const actions = {
	isShowSnackbarHandler(context, payload){
		context.commit('isShowSnackbarHandler', payload);
	},
	snackbarMessageHandler(context, payload){
		context.commit('snackbarMessageHandler', payload);
	}
}

const mutations = {
	isShowSnackbarHandler(state, isShowSnackbar){
		state.isShowSnackbar = isShowSnackbar;
	},
	snackbarMessageHandler(state, snackbarMessage){
		state.snackbarMessage = snackbarMessage;
		state.isShowSnackbar = true;
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}