import Vue from 'vue'
import { mapGetters } from "vuex";
import config from '../config.js'

// 参考https://cn.vuejs.org/v2/guide/plugins.html
export default{
	install(Vue){
		// 1. 添加全局方法或属性
		Vue.myGlobalMethod = function () {
		    // 逻辑...
		}

		// 2. 添加全局资源
		Vue.directive('my-directive', {
		    bind (el, binding, vnode, oldVnode) {
		    	// 逻辑...
			}
		    // ...
		})

		// 3. 注入组件选项
		Vue.mixin({
		    created: function () {

		    },
		    computed:{
	        },
		    methods: {
		    	// 获取信号使用的交易对
			   getSignalSymbols() {
				   var symbols = [];
                	let api = config.baseUrl + '/symbol/public/getSignalSymbols'
                	this.$http.get(api).then(
						response => {
							if (response.data.code == 200) {
								symbols = response.data.result.symbols;
								this.$store.dispatch('signalSymbolsHandler', symbols);
							}
						},
					)
            	},
            	// 查询周期信号交易对
				getIntervalSignalSymbols(){
					let api = config.baseUrl + '/symbol/public/getIntervalSignalSymbols';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('intervalSymbolsHandler', data.result);
						} else {
							this.$store.dispatch('intervalSymbolsHandler', []);
							this.$store.dispatch('snackbarMessageHandler', data.message);
						}
					}, error => {
						this.$store.dispatch('intervalSymbolsHandler', []);
					});
				},
            	// 查询信号周期
				getSignalIntervals(){
					let api = config.baseUrl + '/signal/public/intervals';
					this.$http.get(api).then(response => {
						let data = response.data;
						if (data.code == 200) {
							this.$store.dispatch('signalIntervalsHandler', data.result);
						} else {
							this.$store.dispatch('signalIntervalsHandler', []);
							this.$store.dispatch('snackbarMessageHandler', data.message);
						}
					}, error => {
						this.$store.dispatch('signalIntervalsHandler', []);
					});
				},
				// 查询支持的现货符号集合
		    	getSpotSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getSpotSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
						  this.$store.dispatch('spotSymbolInfosHandler', data.result.symbolInfos);
						  this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', data.result.exchange);
		                } else {
						  this.$store.dispatch('spotSymbolInfosHandler', []);
						  this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', []);
		                  if(data.code != 204){
		                  	this.$store.dispatch('snackbarMessageHandler', data.message);
		                  }
		                }
		            }, error => {
						  this.$store.dispatch('spotSymbolInfosHandler', []);
						  this.$store.dispatch('exchangeSupportedSpotSymbolsHandler', []);
		            });
				},
				// 查询支持的期货符号集合
		    	getFuturesSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getFuturesSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
						  this.$store.dispatch('futuresDecimalNumbersHandler', data.result.decimalNumbers);
						  this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', data.result.exchange);
		                } else {
						  this.$store.dispatch('futuresDecimalNumbersHandler', []);
						  this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', []);
		                  if(data.code != 204){
		                  	this.$store.dispatch('snackbarMessageHandler', data.message);
		                  }
		                }
		            }, error => {
					  this.$store.dispatch('futuresDecimalNumbersHandler', []);
					  this.$store.dispatch('exchangeSupportedFuturesSymbolsHandler', []);
		            });
				},
				// 查询支持的永续合约USDT符号集合
		    	getSwapUsdtSymbols(){
		    		let api = config.baseUrl + '/symbol/public/getSwapUsdtSymbols';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
							  this.$store.dispatch('swapUsdtSymbolInfosHandler', data.result.symbolInfos);
							  this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', data.result.exchange);
		                } else {
							  this.$store.dispatch('swapUsdtSymbolInfosHandler', []);
							  this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', []);
		                  	if(data.code != 204){
		                  		this.$store.dispatch('snackbarMessageHandler', data.message);
		                  	}
		                }
		            }, error => {
						  this.$store.dispatch('swapUsdtSymbolInfosHandler', []);
						  this.$store.dispatch('exchangeSupportedSwapUsdtSymbolsHandler', []);
		            });
		    	},
		    	// 查询支持的交易所集合
		    	getExchanges(){
		    		let api = config.baseUrl + '/exchange/public/getExchanges';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                  this.$store.dispatch('exchangesHandler', data.result);
		                } else {
		                  this.$store.dispatch('exchangesHandler', []);
		                }
		            }, error => {
		              this.$store.dispatch('exchangesHandler', []);
		            });
		    	},
		    	// 交易类别：spot/futures
		    	getTradingCategories(){
		    		let api = config.baseUrl + '/exchange/public/getTradingCategories';
		            this.$http.get(api).then(response => {
		                let data = response.data;
		                if (data.code == 200) {
		                	let arr = new Array();
		                  	for (let i = 0, len = data.result.length; i < len; i++) {
		                  		arr[i] = {
		                  			text: data.result[i].toUpperCase(),
		                  			value: data.result[i]
		                  		}
		                  	}
		                  this.$store.dispatch('tradingCategoriesHandler', arr);
		                } else {
		                  this.$store.dispatch('tradingCategoriesHandler', []);
		                }
		            }, error => {
		              this.$store.dispatch('tradingCategoriesHandler', []);
		            });
		    	},
		    }
		})

		// 4. 添加实例方法
		Vue.prototype.init = function () {
			// 在这里加载所需要的初始化数据，放入缓存以便使用
			// 获取信号使用的交易对
			this.getSignalSymbols();
			// 查询周期信号交易对
			this.getIntervalSignalSymbols();
			// 查询信号周期
			this.getSignalIntervals();
			// 查询支持的现货符号集合
			this.getSpotSymbols();
			this.getFuturesSymbols();
			this.getSwapUsdtSymbols();
			// 查询支持的交易所
		    this.getExchanges();
		    // 查询交易类别
		    this.getTradingCategories();
		}
	}
}
