<template>
    <!-- <v-sheet id="myChart" :style="{width: '600px', height: '165px'}" class="v-sheet--offset mx-auto" color="blue" elevation="12" max-width="calc(100% - 30px)"></v-sheet> -->
    <v-sheet id="myChart" :style="{width: '1200px', height: '320px'}" class="v-sheet--offset mx-auto" color="blue" elevation="12" max-width="calc(100% - 30px)"></v-sheet>
</template>
<script>
    import Vue from 'vue'
    import config from '../config';
    export default {
        data() {
            return {
                dateList: [],
                volumeList: [],
            }
        },
        props:{
            size:null
        },
        mounted() {
            this.getRecentVisit(this.size);
        },
        computed: {

        },
        components: {

        },
        watch: {
            size(newVal,oldVal){
                if(newVal != oldVal){
                    this.getRecentVisit(newVal);
                }
            }
        },
        methods: {
            drawLine() {
                // 基于准备好的dom，初始化echarts实例
                let myChart = this.$echarts.init(document.getElementById('myChart'))
                // 绘制图表
                myChart.setOption({
                    // 标题
                    title: {
                        text: '   Recent '+this.size+' days of visits',
                        textStyle: {
                            color: "white"
                        }
                    },
                    grid: {
                        y: 40,  // 上侧距离
                        y2: 25, // 下侧距离
                        x: 40,  // 左侧距离
                        x2: 40, // 右侧距离
                    },
                    tooltip: {},
                    // x轴
                    xAxis: {
                        type: 'category',
                        data: this.dateList, // 数据:日期
                        axisLine: {
                            lineStyle: {
                                color: "white", // x轴显示为白色
                                width:3,
                            }
                        },
                        splitLine: {
                            show: false // 去掉图中的竖线
                        },
                        "axisTick":{       //刻度线
                            "show":false
                        },
                    },
                    // y轴
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "white", // y轴显示为白色
                                width:3,
                            }
                        },
                        splitLine: {
                            show: false // 去掉图中横线
                        },
                        "axisTick":{       //y轴刻度线
                            "show":false
                        },
                    },
                    series: [
                        // 访问量
                        {
                            name: '访问量',
                            type: 'line',
                            data: this.volumeList,
                            // 点标签数据
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top',
                                    color: "white",
                                }
                            },
                            // symbol:'none', //去掉折线图中的节点
                            // smooth: true,  //true 为平滑曲线，false为直线
                            itemStyle: {
                                normal: {
                                    color:"white",//点颜色
                                    lineStyle:{
                                        width:3,//折线宽度
                                        color:"white"//折线颜色
                                    }
                                }
                            },
                        }
                    ]
                });
            },
            // 获取最近访问量数据
            getRecentVisit(size) {
                let api = config.baseUrl + '/visit/private/getRecentVisit/'+size
                this.$http.get(api).then(
                    response => {
                        if (response.data.code == 200) {
                            this.dateList = response.data.result.dateList;
                            this.volumeList = response.data.result.volumeList;
                            this.drawLine();
                        }
                    },
                )
            },

        }
    }
</script>