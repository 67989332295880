import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user/index'
import data from './modules/data'
import communication from './modules/communication'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    data,
    communication,
},
  state: {

  },
  mutations: {

  },
  actions: {

  }
})
