<template>
  <v-snackbar v-model="isShowSnackbar" multi-line :timeout="3000">
        {{ snackbarMessage }}
        <v-icon class="float-right ml-2" @click="closeSnackbarMessage">close</v-icon>
    </v-snackbar>
</template>

<script>
 import {
    mapGetters
  } from 'vuex';

  export default {
    data: () => ({
      title: null,

    }),
    watch: {
      
    },
    computed: {
      ...mapGetters(['snackbarMessage']),
      // 是否显示提示信息
    isShowSnackbar: {
      get(){
        return this.$store.getters.isShowSnackbar;
      },
      set(v){
        this.$store.dispatch("isShowSnackbarHandler", v);
      }
    },
    },
    mounted() {

    },
    beforeDestroy() {

    },
    methods: {
      // 关闭提示信息
    closeSnackbarMessage(){
      this.$store.dispatch("isShowSnackbarHandler", false);
    },
      
    }
  }
</script>