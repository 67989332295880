<template>
  <div v-if="this.user.token">
    <v-app-bar flat color="grey lighten-3" dense class="py-0 my-0">
      <AppMenus v-if="!isPC()"></AppMenus>
      <v-app-bar-nav-icon @click="recover()" v-if="isPC()"></v-app-bar-nav-icon>
      <div class="fw-700 fs-20">
        <span v-if="hideSide">ioBots - Dashboard</span>
        <span v-if="!hideSide" style="margin-left: 170px">ioBots - Dashboard</span>
        <span v-if="env == 'Development'"> - Development</span>
        <span v-if="env == 'Test'"> - Testnet</span>
      </div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="text-center mt-2">
          <!-- 用户头像 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="36" class="mr-2" v-bind="attrs" v-on="on">
                <img :src="user.avatar">
              </v-avatar>
            </template>
            <span>{{ user.username }}</span>
          </v-tooltip>
          <!-- 退出按钮 -->
          <v-btn icon fab @click="signOut" title="Signout" x-small>
            <v-icon>fas fa-sign-out-alt</v-icon>
          </v-btn>
        </div>
      </v-toolbar-items>
    </v-app-bar>
    <app-drawer @hide="hide()" v-if="!hideSide"></app-drawer>
    <v-btn v-show="scrollTop >= 300" color="blue darken-2" x-small dark fab bottom right fixed @click="$vuetify.goTo(0)">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import AppMenus from './Menus'
  import AppDrawer from './Drawer.vue'
  import config from '../config';
  export default {
    data: () => ({
      title: null,
      hideSide:true,
      env: config.environment,
      // 前页面滚动了多少px
      scrollTop: 0
    }),
    watch: {
      '$route'(val) {
        this.title = val.name
      }
    },
    computed: {
      ...mapGetters(['user',]),
    },
    components:{
      AppMenus,
      AppDrawer,
    },
    mounted() {
      window.onscroll=()=>{
        this.scrollTop = document.documentElement.scrollTop;
      }
    },
    beforeDestroy() {
    },
    methods: {
      //退出
      signOut() {
        localStorage.removeItem("iobots-admin-user");
        localStorage.removeItem("iobots-admin-token");
        localStorage.removeItem("iobots-admin-expires");
        this.$router.push({
          name: 'home'
        });
        this.$router.go();
      },
      // 隐藏侧边栏
      hide(){
        this.hideSide = true;
      },
      // 显示侧边栏
      recover(){
        this.hideSide = false;
      },
      isPC (){
        var userAgentInfo = navigator.userAgent;
        var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
        var flag = true;
        for (var i = 0; i < Agents.length; i++) {
          if (userAgentInfo.indexOf(Agents[i]) > 0) {
            flag = false;
            break;
          }
        }
        return flag;
      },
    }
  }
</script>