import Vue from 'vue'
import Router from     'vue-router'

// import Home from       '@/pages/home/Home.vue'
// import Login from      '@/pages/user/Login'
// import User from       '@/pages/user/User'
// import Signals from    '@/pages/signal/Signals'
// import Portfolio from  '@/pages/signal/Portfolio'
// import Visit from      '@/pages/system/Visit'
// import Ledger from     '@/pages/ledger/Ledger'
// import Page404 from    '@/global/Page404'

const Home = () => import     ( '@/pages/home/Home.vue' )
const Login = () => import    ( '@/pages/user/Login' )
const User = () => import     ( '@/pages/user/User' )
const FundNAV = () => import  ( '@/pages/user/FundNAV.vue' )
const NAV = () => import  ( '@/pages/user/NAV.vue' )
const TradingRecord = () => import  ( '@/pages/user/TradingRecord.vue' )
const Signals = () => import  ( '@/pages/signal/Signals' )
const Data = () => import  ( '@/pages/signal/Data' )
const Portfolio = () => import( '@/pages/signal/Portfolio' )
const Visit = () => import    ( '@/pages/system/Visit' )
const Redis = () => import    ( '@/pages/system/Redis' )
const BinanceMarkets = () => import    ( '@/pages/market/BinanceMarkets' )
const KrakenMarkets = () => import    ( '@/pages/market/KrakenMarkets' )
const OkexMarkets = () => import    ( '@/pages/market/OkexMarkets' )
const Notifications = () => import    ( '@/pages/system/Notifications' )
const Research = () => import    ( '@/pages/research/Research' )
const CryptoPriceMarketCap = () => import    ( '@/pages/cmc/CryptoPriceMarketCap' )
const Address = () => import    ( '@/pages/address/Address' )
const Version = () => import    ( '@/pages/system/Version' )
const Pool = () => import    ( '@/pages/pool/Pool' )
const PoolFeeStats = () => import    ( '@/pages/pool/PoolFeeStats' )
const HAStats = () => import    ( '@/pages/heikinAshi/HAStats' )
const HAStatsSummary = () => import    ( '@/pages/heikinAshi/HAStatsSummary' )
const BlockchainAsset = () => import    ( '@/pages/asset/BlockchainAsset' )
const PoolAssetAddress = () => import    ( '@/pages/asset/PoolAssetAddress' )
const Exchange = () => import    ( '@/pages/exchange/Exchange' )
const Symbols = () => import    ( '@/pages/exchange/Symbol' )
// const Ledger = () => import   ( '@/pages/ledger/Ledger' )
const Page404 = () => import  ( '@/global/Page404' )

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'haStatsSummary', component:HAStatsSummary},
    { path: '/overview',name: 'home',component: Home},
    { path: '/user/login',name: 'login',component: Login},
    { path: '/signals',name: 'signals',component:Signals},
    { path: '/data',name: 'data',component:Data},
    { path: '/user',name: 'user',component:User},
    { path: '/user/fundNAV',name: 'fund',component:FundNAV},
    { path: '/user/NAV',name: 'nav',component:NAV},
    { path: '/user/tradingRecord',name: 'record',component:TradingRecord},
    { path: '/visit',name: 'visit',component:Visit},
    { path: '/system/redis',name: 'redis',component:Redis},
    { path: '/market/BinanceMarkets',name: 'BinanceMarkets',component:BinanceMarkets},
    { path: '/market/KrakenMarkets',name: 'KrakenMarkets',component:KrakenMarkets},
    { path: '/market/OkexMarkets',name: 'OkexMarkets',component:OkexMarkets},
    { path: '/notifications',name: 'notifications',component:Notifications},
    { path: '/research',name: 'research',component:Research},
    { path: '/portfolio',name: 'portfolio',component:Portfolio},
    { path: '/CryptoPriceMarketCap',name: 'cryptoPriceMarketCap',component:CryptoPriceMarketCap},
    { path: '/address',name: 'address',component:Address},
    { path: '/version',name: 'version',component:Version},
    { path: '/pool',name: 'pool',component:Pool},
    { path: '/pool/fee/stats',name: 'poolFeeStats',component:PoolFeeStats},
    { path: '/pool/asset/blockchain', name: 'blockchainAsset', component:BlockchainAsset},
    { path: '/pool/asset/detail', name: 'poolAssetAddress', component:PoolAssetAddress},
    { path: '/heikin-ashi/stats', name: 'haStats', component:HAStats},
    { path: '/exchange', name: 'exchange', component:Exchange},
    { path: '/symbol', name: 'symbol', component:Symbols},
    { path: '*', name:'page404', component:Page404 },// 404
  ]
})
