<template>
  <v-app class="v-application">
    <app-header></app-header>
    <v-content>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-content>
    <app-footer></app-footer>
  </v-app>
</template>
<script>
  import Vue from 'vue'
  import config from './config';
  import AppHeader from './components/Header.vue'
  import AppFooter from './components/Footer.vue'
  import AppCharts from './components/Charts.vue'
  import PortfolioResult from './pages/signal/PortfolioResult'
  import PortfolioLast from './pages/signal/PortfolioLast'
  import CountTo from 'vue-count-to';
  // Vue.use(AppDrawer);
  import { mapGetters } from "vuex";

  Vue.component('AppCharts', AppCharts);
  Vue.component('PortfolioResult', PortfolioResult);
  Vue.component('PortfolioLast', PortfolioLast);
  Vue.component('CountTo', CountTo);
  

  export default {
    name: 'App',
    components: {
      AppHeader,
      AppFooter,
    },
    data: () => ({}),
    created: function () {
      // 数字格式化
            Vue.filter('NumFormat', function(value) {
                if (value == '--') return '--';
                if(!value) return '0.00000';
                var intPart = Number(value).toFixed(0); //获取整数部分
                intPart = intPart > Number(value) ? intPart - 1 : intPart; // 若四舍五入进了1则减去1
                var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
                var floatPart = ".00000"; //预定义小数部分
                var value2Array = value.split(".");
                //=2表示数据有小数位
                if(value2Array.length == 2) {
                    floatPart = value2Array[1].toString(); //拿到小数部分
                    if(floatPart.length == 1) { //补0,实际上用不着
                        return intPartFormat + "." + floatPart + '0';
                    } else {
                        return intPartFormat + "." + floatPart;
                    }
                } else {
                    return intPartFormat + floatPart;
                }
            });

    },
    computed: {
      ...mapGetters(['user']),
    },
    mounted(){
      if (!this.user.token) {
          this.$router.push({
              name: 'login'
          })
      };
      this.onMovableDialog();
      // if(Number(localStorage.getItem("iobots-admin-expires"))-((new Date()).getTime()) < 0){
      //   localStorage.removeItem("iobots-admin-user");
      //   localStorage.removeItem("iobots-admin-password");
      //   localStorage.removeItem("iobots-admin-token");
      // }
			// this.addVisitVolume();
		},
    methods:{
      // addVisitVolume(){
			// 	this.$http.get(config.baseUrl + "/visit/open/visit");
			// },
          // 可移动的弹出框
      onMovableDialog(){
        const d = {};
        document.addEventListener("mousedown", e => {
            const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
            if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title")) { // element which can be used to move element
                d.el = closestDialog; // element which should be moved
                d.mouseStartX = e.clientX;
                d.mouseStartY = e.clientY;
                d.elStartX = d.el.getBoundingClientRect().left;
                d.elStartY = d.el.getBoundingClientRect().top;
                d.el.style.position = "fixed";
                d.el.style.margin = 0;
                d.oldTransition = d.el.style.transition;
                d.el.style.transition = "none"
            }
        });
        document.addEventListener("mousemove", e => {
            if (d.el === undefined) return;
            d.el.style.left = Math.min(
                Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
                window.innerWidth - d.el.getBoundingClientRect().width
            ) + "px";
            d.el.style.top = Math.min(
                Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
                window.innerHeight - d.el.getBoundingClientRect().height
            ) + "px";
        });
        document.addEventListener("mouseup", () => {
            if (d.el === undefined) return;
            d.el.style.transition = d.oldTransition;
            d.el = undefined
        });
        setInterval(() => { // prevent out of bounds
            const dialog = document.querySelector(".v-dialog.v-dialog--active");
            if (dialog === null) return;
            dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
            dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
        }, 1);
      },

    }
  };
</script>