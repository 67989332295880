export default {

    // 格式化日期
    dateFormat: function (time) {
        var date = new Date(time);
        var year = date.getFullYear();
        /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
        var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        // 拼接
        return (month + "/" + day + "/" + year);
    },


    // 格式化时间
    formatTime(timestamp){
        // 在日期格式中，月份是从0开始的，因此要加0
        // 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11
        var date = new Date(timestamp);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? ('0' + month) : month;
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        // return month + '/' + day + '/' +  + ' ' + hours + ':' + minutes + ':' + seconds;
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },

    // 格式化数字
     formatNum: function (num) {
        if (num == null) return '--';
        if (num == NaN || num == 'NaN') return '--';
        if (num == 'undefined') return '--';
        if (num == '--') return '--';
        let number = num + '';
        let numberArray = number.split('.');
        let integerPart = numberArray[0];
        let decimalPart = numberArray.length > 1 ? '.' + numberArray[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(integerPart)) {
            integerPart = integerPart.replace(rgx, '$1' + ',' + '$2');
        }
        return integerPart + decimalPart;
    },


    
}